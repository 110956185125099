import { Box, Image, Link, Divider, Text, Button, List, ListItem } from "@chakra-ui/react"
import logo from "../../assets/img/logo-white.png"
import { ReactComponent as Facebook } from "../../assets/img/facebook.svg"
import { ReactComponent as Instagram } from "../../assets/img/instagram.svg"
import { ReactComponent as Youtube } from "../../assets/img/youtube.svg"

export default function LomaFooter() {
    return (
        <Box
            color="white"
            width="100%"
            paddingY="30px"
            paddingX="12px"
            marginTop="10px"
            background="#323232"
            
        >
            <Box
                width="100%"
                maxW="850px"
                display="block"
                marginX="auto"
            >
            <Box
                display="flex"
                flexDirection={{
                    base: "column",
                    sm: "row"
                }}

            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems={{
                        sm: "center"
                    }}
                >
                    <Image 
                        src={logo} 
                        alt="Logo Loma"
                        height="40px"
                    ></Image>
                </Box>
                <Box
                    paddingY={{
                        base: "20px",
                        sm: "0px"
                    }}
                    display="block"
                    marginX="auto"
                    marginRight={{
                        sm: "0px"
                    }}
                    marginBottom={{
                        sm: "20px"
                    }}
                    width={{
                        base: "205px",
                        sm: "165px"
                    }}
                >
                    <Box
                        height={{
                            base: "60px",
                            sm: "50px"
                        }}
                        width={{
                            base: "205px",
                            sm: "165px"
                        }}
                        columnGap="5px"
                        display="grid"  
                        gridTemplateColumns="1fr 1fr 1fr"  
                    >
                        <Link
                            href="https://www.facebook.com/lomabemprotegido"
                            target="_blank"
                        >
                            <Facebook
                                width="100%"
                                height="100%"
                            />
                        </Link>
                        <Link
                            href="https://www.instagram.com/lomabemprotegido/"
                            target="_blank"
                        >
                            <Instagram
                                width="100%"
                                height="100%"
                            />
                        </Link>
                        <Link
                            href="https://www.youtube.com/channel/UC6GMS-ir47Zh3rzJDooHgWA"
                            target="_blank"
                        >
                            <Youtube
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Box>
                </Box>
            </Box>
            <Box
                width="100%"
                display="block"
                marginX="auto"
            >
                <Divider/>
            </Box>
            <Box
                display={{
                    sm: "flex"
                }}
                justifyContent="space-between"
            >
                <Box>
                    <Box
                        paddingY="20px"
                    >
                        <Text>Av. Dr.Odil Campos de Sáes, 470 - Jardim Sao Bento, Jundiaí - SP, 13207-145</Text>
                    </Box>
                    <Box
                        fontWeight="black"
                    >
                        <Text>Telefone: </Text><Text
                            color="redBrand.500"
                        >(11) 4200-0837</Text>
                    </Box>
                </Box>
                <Box
                    paddingY="15px"
                    fontWeight="600"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    maxW="200px"
                >
                    <Box>
                        <Link
                            href="https://lomabemprotegido.com.br/simulacao"
                            target="_blank"
                        >
                            <Button 
                                width="100%"
                                colorScheme="redBrand"
                                paddingX="30px"
                                borderRadius="30px"
                            >
                                SIMULAR AGORA
                            </Button>
                        </Link>
                    </Box>
                    <Box
                        marginTop="5px"
                    >
                        <Link
                            href="https://lomabemprotegido.com.br/"
                            target="_blank"
                        >
                            <Button 
                                width="100%"
                                colorScheme="redBrand"
                                variant="outline"
                                paddingX="30px"
                                borderRadius="30px"
                            >
                                SITE DO ASSOCIADO
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box
                    paddingY={{
                        sm: "20px"
                    }}
                >

                    <List
                        display={{
                            sm: "flex"
                        }}
                        justifyContent="space-around"
                    >
                        <ListItem>
                            <Link
                                textDecoration="underline"
                                href="https://lomabemprotegido.com.br/#sobre-a-loma"
                                target="_blank"
                            >

                                O que é a LOMA
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                                textDecoration="underline"
                                href="https://lomabemprotegido.com.br/#como-se-associar"
                                target="_blank"
                            >

                                Como se associar
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                                textDecoration="underline"
                                href="https://lomabemprotegido.com.br/#veiculos"
                                target="_blank"
                            >

                                Veículos
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                                textDecoration="underline"
                                href="https://lomabemprotegido.com.br/#app-loma"
                                target="_blank"
                            >

                                App LOMA
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                                textDecoration="underline"
                                href="https://lomabemprotegido.com.br/#reconhecimentos"
                                target="_blank"
                            >

                                Reconhecimentos
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                                textDecoration="underline"
                                href="https://lomabemprotegido.com.br/#assistencia"
                                target="_blank"
                            >

                                0800
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                                textDecoration="underline"
                                href="https://lomabemprotegido.com.br/#faq"
                                target="_blank"
                            >

                                FAQ
                            </Link>
                        </ListItem>
                    </List>
                </Box>
                <Box>
                    <Box
                        marginTop="5px"
                        textAlign="center"
                        fontWeight="600"
                    >
                        <Link
                            href="https://jobs.kenoby.com/lomacarreiras"
                            target="_blank"
                        >
                            TRABALHE CONOSCO
                        </Link>
                    </Box>
                </Box>
            </Box>
            </Box>
        </Box>
    )
}