import { Text, Box } from "@chakra-ui/react";
import shortid from "shortid"

type RenderTextType = {
    children: any
    paddingLeft: string
    paddingY: string
    display: string
}

export default function RenderText(props:RenderTextType) {
    
    return <Box {...props}>
        {props.children.split("\n").map((parsedText:string) => {
            return <Text key={shortid.generate()}>{parsedText}</Text>
        })}
    </Box>
}