import FaqItem from "./faqItem"

type FaqItemType = {
    id: number
    title: string
    content: string
}

type FaqItemComponentProps = {
    data: Array<FaqItemType>
}

export default function FAQComponent(props:FaqItemComponentProps) {
    

    return (
        <>
        {
            props.data.map((faqItem:FaqItemType) => {
                return <FaqItem key={faqItem.id} faqItem={faqItem}></FaqItem>
            })
        }
        </>
    );
}

export type { FaqItemType }

