import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Assedio from "./routes/assedio";
const router = createBrowserRouter([{
  path: "*",
  element: <Assedio/>
}])



function App() {
  localStorage.setItem("chakra-ui-color-mode", "light")
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
