import { useState, useCallback, useEffect, useRef } from "react"
import { Box, Heading, Text } from "@chakra-ui/react"
import type { FaqItemType } from "."
import RenderText from "../RenderText"

type PropsFaqItem = {
    faqItem: FaqItemType
}

export default function FaqItem(props:PropsFaqItem) {

    const faqItemContainerRef:any = useRef(null);
    const faqItemContentRef:any = useRef(null);

    const [ showContent, setShowContent ] = useState(false);

    const showContentCallBack = useCallback(() => {
        setShowContent((val) => !val);
    }, [])

    useEffect(() => {
        if(faqItemContainerRef !== null && faqItemContentRef !== null) {
            faqItemContainerRef.current.style.height = `${faqItemContentRef.current.scrollHeight}px`
        }
    }, [showContent, faqItemContainerRef, faqItemContentRef])

    return <Box
        ref={faqItemContainerRef}
        onClick={showContentCallBack}
        transition="all 0.3s ease-out"
        
    >
        <Box
            background="white"
            
            shadow="xl"
            marginY="10px"
            paddingY="20px"
            paddingX="15px"
            borderRadius="20px"
            ref={faqItemContentRef}
        >
            <Box
                display="flex"
            >
                <Text
                    color="redBrand.500"
                    paddingX="10px"
                    fontSize="15px"
                >
                    {showContent ? "-" : "+"}
                </Text>
                <Heading size="smden">{props.faqItem.title}</Heading>
            </Box>
            <RenderText
                paddingLeft="30px"
                paddingY="10px"
                display={showContent ? "block" : "none" }
            >{props.faqItem.content}</RenderText>
        </Box>
    </Box>
}